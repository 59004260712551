import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NotFound from './NotFound';

export default function DetectionStreamPage(props) {
    let imageElement;
    let lblDescription;
    let lblCoordinates;

    let [isLoading, setIsLoading] = useState(false);
    let [error, setError] = useState('');

    let droneId = null;
    let { state } = useLocation();

    useEffect(() => {
        if (!isLoading && !error) {
            imageElement = document.querySelector('#currentImage');
            lblDescription = document.querySelector('#lblDescription');
            lblCoordinates = document.querySelector('#lblCoordinates');
        }
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(true);

        if (window.sessionStorage.getItem('access_token') == null) {
            setIsLoading(false);
            props.navigate('/login', { replace: true });
            return;
        }

        droneId = state?.droneId;

        // TODO: Type check

        if (droneId == null || typeof droneId !== 'number') {
            setIsLoading(false);
            props.navigate("/drones", { replace: true });
            return;
        }

        let webSocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_BASE_URL + '/api/detections/ws?authorization=' + window.sessionStorage.getItem('access_token') + '&droneId=' + droneId.toString() + '&socketType=1');

        let lastFrameTime = Date.now();

        webSocket.onmessage = function (event) {
            let jsonData;

            try {
                jsonData = JSON.parse(event.data);
            } catch (e) {
                console.error(e);
            }

            if (jsonData == null) {
                if (event.data === 'ping') {
                    webSocket.send('pong');
                }
            }
            else {
                if (jsonData.status === 'ERROR') {
                    setIsLoading(false);
                    setError(jsonData.message);
                    return;
                } else if (jsonData.type === 'DETECTION' && jsonData.image != null) {
                    if (imageElement)
                        imageElement.src = jsonData.image;

                    const currentTime = Date.now();

                    const timeDifference = currentTime - lastFrameTime;

                    console.log('Aradan geçen zaman: ' + timeDifference.toString() + ' ms, ' + (1000.0 / timeDifference).toString() + ' FPS');
                    lastFrameTime = currentTime;

                    if (lblDescription)
                        lblDescription.textContent = 'Açıklama: ' + jsonData.description;

                    if (lblCoordinates)
                        lblCoordinates.textContent = 'Koordinatlar: ' + jsonData.location.Latitude + ', ' + jsonData.location.Longitude + ', ' + jsonData.location.Altitude + ' metre';
                }
            }
        }

        webSocket.onerror = function (event) {
            console.error(event);
        }

        webSocket.onopen = function (event) {
        }

        webSocket.onclose = function (event) {
            if (event.wasClean) {
                setError(event.reason || 'Bağlantı kapatıldı.');
                console.info(`WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                setError(event.reason || 'Bağlantı beklenmedik bir şekilde kapatıldı.');
                console.error('WebSocket connection died.');
            }
        }

        setIsLoading(false);

        return () => {
            webSocket?.close();
        };
    }, []);

    if (isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <h1>Yükleniyor...</h1>
                </div>
            </div>
        );
    }
    else if (error !== '') {
        return (
            <NotFound title={error} navigateTo='/drones' linkDescription='< İHA sayfasına geri dön' />
        )
    }

    return (
        <>
            <h5>Tespit Edilen Hastalıklı Bitkiler</h5>
            <hr />
            <div>
                <img id='currentImage' alt='Tespit edilen hastalıklı bitki' />
            </div>
            <hr />
            <div>
                <label id='lblDescription'>Açıklama: -</label>
            </div>
            <hr />
            <div>
                <label id='lblCoordinates'>Koordinatlar: -</label>
            </div>
            <hr />
        </>
    )
}