import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NotFound from './NotFound';

function DetectionsPage(props) {
    let [isLoading, setIsLoading] = useState(true);
    let [error, setError] = useState('');
    let [detectionList, setDetectionList] = useState([]);
    let [currentIndex, setCurrentIndex] = useState(0);

    let droneId = null;
    let { state } = useLocation();
    droneId = state?.droneId;

    let listContainer;
    let loadingContainer;

    useEffect(() => {
        if (error !== '') {
            console.error('Bir hata oluştuğundan dolayı içerik yüklenmeyecek.');
            return;
        }

        if (!isLoading) {
            listContainer = document.querySelector('#detection-list-container');
            loadingContainer = document.querySelector('#loading-container');

            if (!listContainer || !loadingContainer) {
                // TODO: Kullanıcıya hata göster.
                console.error('Listeyi göstermek için gerekli öğeler bulunamadı.');
                props.navigate('/drones', { replace: true });
                return;
            }

            while (listContainer.firstChild) {
                if (listContainer.firstElementChild.id === loadingContainer.id)
                    break;
                listContainer.removeChild(listContainer.firstChild);
            }

            listContainer.onscroll = function () {
                if (
                    Math.ceil(listContainer.clientHeight
                        + listContainer.scrollTop) >=
                    listContainer.scrollHeight
                ) {
                    fetchData();
                }
            };

            fetchData();
        }
    }, [isLoading]);

    useEffect(() => {
        if (window.sessionStorage.getItem('access_token') == null) {
            props.navigate('/login', { replace: true });
            return;
        }
        else if (droneId == null || typeof droneId !== 'number') {
            props.navigate('/drones', { replace: true });
            return;
        }

        fetch(process.env.REACT_APP_BASE_URL + '/api/detections/getlistbydroneid?droneId=' + droneId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + window.sessionStorage.getItem('access_token')
            }
        })
            .then(response => {
                //console.debug('getlistbydroneid isteğinin sonucu: ', response.status, response.statusText);
                if (response.status === 401) {
                    props.navigate('/login', { replace: true });
                    return;
                }
                else if (!response.ok)
                    throw response.statusText != '' ? response.statusText : 'Sunucuyla bağlantı kurarken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.';
                return response
            })
            .then(response => response.json())
            .then(responseJson => {
                const arr = (responseJson.data ?? []).sort((a, b) => a.id - b.id);

                setDetectionList(arr);

                if (arr.length > 0)
                    setCurrentIndex(arr.length - 1);
                else
                    setError('Herhangi bir tespit bulunamadı.');

                setIsLoading(false);
            })
            .catch(err => {
                //throw err;
                console.error(err);

                setError(err.message ?? err);

                setIsLoading(false);
            });
    }, []);

    async function fetchData() {
        if (isLoading)
            return;

        loadingContainer.style.visibility = '';

        const fixedCurrentIndex = currentIndex;

        for (; currentIndex > fixedCurrentIndex - 10 && currentIndex >= 0; currentIndex--) {
            const detectionId = detectionList[currentIndex].id;

            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/detections/getimage?id=' + detectionId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem('access_token')
                    }
                });

                if (!response.ok)
                    continue;

                createRowForDetectionData(detectionList[currentIndex], response);
            } catch (e) {
                console.error(e);
            }
        }

        loadingContainer.style.visibility = 'hidden';
    }

    function createRowForDetectionData(detectionData, imgResponse) {
        const container = document.createElement('div');
        container.className = 'list-group-item d-flex flex-column justify-content-center align-items-center';

        const idElement = document.createElement('div');
        idElement.textContent = 'Tespit ID: ' + detectionData.id;

        const descriptionElement = document.createElement('div');
        descriptionElement.textContent = detectionData.description;

        const locationElement = document.createElement('div');
        locationElement.style = 'display:flex;flex-direction:column;align-items:center';

        const locationSpan = document.createElement('span');
        locationSpan.textContent = 'Koordinatlar: ' + detectionData.location.latitude + ', ' + detectionData.location.longitude + ', ' + detectionData.location.altitude + ' metre yükseklikten';

        const locationButton = document.createElement('button');
        locationButton.className = 'button-primary text-fg-white';
        locationButton.textContent = 'Haritada göster';
        /*locationButton.href = `https://www.google.com/maps/dir/40.9406423,29.3019899/${detectionData.location.latitude},${detectionData.location.longitude}/@${detectionData.location.latitude},${detectionData.location.longitude}`;*/
        locationButton.onclick = (event) => {
            // TODO: Geolocation izni işlenirken bekleme ekranı göstermek.

            //props.navigate(`/map?lat=${detectionData.location.latitude}&lng=${detectionData.location.longitude}`);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    window.location.href = `https://www.google.com/maps/dir/${position.latitude},${position.longitude}/${detectionData.location.latitude},${detectionData.location.longitude}/@${detectionData.location.latitude},${detectionData.location.longitude}`;
                }, (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.debug("User denied the request for Geolocation.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.debug("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.debug("The request to get user location timed out.");
                            break;
                        case error.UNKNOWN_ERROR:
                            console.debug("An unknown error occurred.");
                            break;
                    }

                    window.location.href = `https://www.google.com/maps/dir/${detectionData.location.latitude},${detectionData.location.longitude}/@${detectionData.location.latitude},${detectionData.location.longitude}`;
                });
            }
            else {

            }
        };

        const imageElement = document.createElement('img');
        imageElement.id = 'detectionImage' + detectionData.id;

        // FileReader support
        if (FileReader) {
            var fr = new FileReader();
            fr.onload = function () {
                imageElement.src = fr.result;
            }
            imgResponse.blob()
                .then(blob => fr.readAsDataURL(blob))
                .catch(error => console.error(error));
        }
        // TODO: FileReader desteği olmaması durumunu araştır

        locationElement.appendChild(locationSpan);
        locationElement.appendChild(document.createElement('br'));
        locationElement.appendChild(locationButton);

        container.appendChild(idElement);
        container.appendChild(descriptionElement);
        container.appendChild(locationElement);
        container.appendChild(imageElement);

        listContainer.insertBefore(container, loadingContainer);
    }

    if (isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <h1>Yükleniyor...</h1>
                </div>
            </div>
        );
    }
    else if (error) {
        return (
            <NotFound title={error} navigateTo='/drones' linkDescription='< İHA sayfasına geri dön' />
        )
    }

    return (
        <>
            <h5>Tespit Edilen Hastalıklı Bitkiler</h5>
            <hr />
            <div id='detection-list-container' className='list-group overflow-auto' style={{ maxHeight: '75vh' }}>
                <div id='loading-container' className='list-group' style={{ visibility: 'hidden' }}>
                    Yükleniyor...
                </div>
            </div>
        </>
    )
}

export default DetectionsPage;