import { useEffect } from "react";
import { Outlet } from "react-router-dom";

function HomePage(props) {
    useEffect(() => {
        if (window.sessionStorage.getItem('access_token') == null) {
            props.navigate('/login');
            return;
        }
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
                <div className="px-3 me-auto">
                    <a className="navbar-brand" href="#">MOSTAŞ</a>
                </div>
                <div className="me-3">
                    <a className="navbar-text text-bg-primary" href="#" onClick={() => { window.sessionStorage.clear(); props.navigate("/login") }}>Çıkış</a>
                </div>
            </nav >
            <div className="container">
                <Outlet />
            </div>
        </>
    );
}

export default HomePage;