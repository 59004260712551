import { useState, useEffect } from 'react';

function LoginPage(props) {
    let [error, setError] = useState('');

    useEffect(() => {

    }, [error]);

    useEffect(() => {
        if (window.sessionStorage.getItem('access_token') != null) {
            props.navigate('/', { replace: true });
            return;
        }
    });

    return (
        <div className='container w-100 h-100 col-md-4'>
            <h5>MOSTAŞ - Kullanıcı Paneli</h5>
            <br />
            {error && <h6>{error}</h6>}
            <h6></h6>
            <form id='loginForm' action={process.env.REACT_APP_BASE_URL + '/api/auth/login'} method='post' onSubmit={handleFormSubmit}>
                <div className='form-group row'>
                    <label className='col-sm-3 col-form-label col-form-label-md' htmlFor='email'>E-posta</label>
                    <input className='col form-control' type='email' id='email' name='email' placeholder='E-posta' required />
                </div>
                <div className='form-group row mt-3'>
                    <label className='col-sm-3 col-form-label col-form-label-md' htmlFor='password'>Şifre</label>
                    <input className='col form-control' type='password' id='password' name='password' placeholder='Şifre' required />
                </div>
                <div className='form-group row mt-3 justify-content-center'>
                    <button className='text-bg-primary' type='submit'>Giriş Yap</button>
                </div>
            </form>
        </div>
    );

    async function handleFormSubmit(event) {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);

        fetch(form.action, {
            'method': form.method ?? 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify({
                'email': formData.get('email'),
                'password': formData.get('password')
            })
        })
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.token) {
                    window.sessionStorage.setItem('access_token', response.token);
                    props.navigate('/', { replace: true });
                    return;
                }
                setError(response.message);
            })
            .catch(error => {
                console.error(error);

                // TODO: Kullanıcıya uygun hata göster.
                setError('Bir hata oluştu, daha sonra tekrar deneyin.');
                /*setError(error.toString());*/
            });
    }
}

export default LoginPage;