import { Link } from 'react-router-dom';

export default function NotFound(props) {
    return (
        <div>
            <h1>{props.title ?? 'Böyle bir yol bulunamadı !'}</h1>
            <hr></hr>
            <Link to={props.navigateTo ?? '/'} replace>{props.linkDescription ?? 'Ana sayfa'}</Link>
        </div>
    )
}