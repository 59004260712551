//import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Link } from 'react-router-dom';
import './assets/css/App.css';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import NotFound from './components/NotFound';
import DetectionStreamPage from "./components/DetectionStreamPage";
import DronePage from "./components/DronePage";
import DetectionsPage from "./components/DetectionsPage";
import GoogleMaps from './components/GoogleMaps';

function App() {
    const navigate = useNavigate();

    // TODO: Session'ı duruma göre burada iptal etmek

    return (
        <>
            <Routes path="/">
                <Route path="" element={< HomePage navigate={navigate} />}>
                    <Route path="" element={
                        <div className="row bg-outline-primary">
                            <Link to="drones">İHA'larım</Link>
                        </div>
                    } />
                    <Route path="detection-stream" element={<DetectionStreamPage navigate={navigate} />} />
                    <Route path="detections" element={<DetectionsPage navigate={navigate} />} />
                    <Route path="drones" element={<DronePage navigate={navigate} />} />
                    {/*<Route path="map" element={<GoogleMaps navigate={navigate} />} />*/}
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/login" element={<LoginPage navigate={navigate} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

export default App;