import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NotFound from "./NotFound";

function DronePage(props) {
    const [droneList, setDroneList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [globalError, setGlobalError] = useState('');

    useEffect(() => {
        getDrones();
    }, []);

    useEffect(() => {
        switch (globalError) {
            case 'Tekrar giriş yapmanız gerekmektedir.':
                window.sessionStorage.removeItem('access_token');
                props.navigate('/login', { replace: true });
                break;
            default:
                break;
        }
    }, [globalError]);

    if (isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <h1>Yükleniyor...</h1>
                </div>
            </div>
        );
    }
    else if (globalError !== '') {
        return (
            <NotFound navigateTo='/' title={globalError} linkDescription='< Ana sayfaya geri dön' />
        );
    }
    else if (droneList.length === 0) {
        return (
            <NotFound navigateTo='/' title='Bu kullanıcıya ait herhangi bir İHA bulunamadı.' linkDescription='< Ana sayfaya geri dön' />
        );
    }
    else {
        return (
            <table className="table table-dark table-bordered table-hover align-middle">
                <thead className="bg-dark">
                    <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>User ID</th>
                        <th>Model</th>
                        <th>Durum</th>
                        <th>Yazılım Sürümü</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        droneList.map((drone, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{drone.droneId}</td>
                                <td>{drone.userId}</td>
                                <td>{drone.model}</td>
                                <td>{drone.status}</td>
                                <td>{drone.softwareVersion}</td>
                                <td>
                                    {/*<button className="btn btn-primary me-2" role="button" disabled>Edit</button>*/}
                                    {/*<button className="btn btn-danger me-2" role="button" disabled>Delete</button>*/}
                                    <Link
                                        to="/detection-stream"
                                        state={{
                                            droneId: drone.droneId
                                        }}
                                        className="btn btn-primary mx-1">
                                        Tespitleri izle
                                    </Link>
                                    <Link
                                        to="/detections"
                                        state={{
                                            droneId: drone.droneId
                                        }}
                                        className="btn btn-primary mx-1">
                                        Eski tespitleri görüntüle
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table >
        );
    }

    async function getDrones() {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + "/api/drone/getlistbyuser", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + window.sessionStorage.getItem("access_token")
                }
            });

            if (response.status === 401) {
                const errorMessage = 'Tekrar giriş yapmanız gerekmektedir.';
                console.error(errorMessage);
                setGlobalError(errorMessage);
                return;
            }

            const responseJson = await response.json();

            setDroneList(responseJson);
        } catch (error) {
            console.error(error);

            setGlobalError('İHA bilgileri alınırken bir sorun oluştu.');
        }
        finally {
            setIsLoading(false);
        }
    }
}

export default DronePage;